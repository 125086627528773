/** @jsx jsx */
import React from 'react';
import { jsx, Text } from 'theme-ui';
import MeasuredIngredient from './measured-ingredient';
import RecipeStep from './recipe-step';
import MeasurementToggleButtons from './measurement-toggle-buttons';
import { useMedia } from '../hooks';
import { tabletGrid, desktopContainerMarginX, desktopGrid } from './const';
import PortableText from './portableText';

const Hr = ({ className }) => (
  <div
    className={className}
    sx={{
      height: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'lightGray',
      borderTopWidth: '1px',
    }}
  ></div>
);

const Ingredients = ({ isKorean, ingredients }) => (
  <div
    sx={{
      display: 'grid',
      gridTemplateColumns: '1fr',
      rowGap: [24, 26, 28],
      gridRowGap: [24, 26, 28],
    }}
  >
    {ingredients.map(({ _key, title, ingredients: list }) => (
      <div
        key={_key}
        sx={{
          fontFamily: 'recipeBody',
          fontSize: [14, 16, 16],
          lineHeight: ['1.71em', '1.625em', '1.75em'],
        }}
      >
        <p
          sx={{
            fontWeight: 'medium',
          }}
        >
          {title}
        </p>
        {(list || []).map(item => (
          <MeasuredIngredient
            key={item._key}
            unit={item.unit}
            amount={item.amount}
            fraction={item.fraction}
            ingredient={item.ingredient}
            customAmount={item.customAmount}
            description={item.description}
            isKorean={isKorean}
          />
        ))}
      </div>
    ))}
  </div>
);

const SmallView = ({
  isKorean,
  _rawRecipe: { servings, ingredients, steps, tip },
}) => (
  <div
    sx={{
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'lightGray',
    }}
  >
    <Text variant="recipeTitle" sx={{ mx: [16, 18], my: [16, 20] }}>
      {isKorean ? '만든 과정' : 'Recipe'}
    </Text>
    <Hr />
    <div sx={{ px: [16, 18], py: [14, 16] }}>
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Text variant="recipeBody">{servings}</Text>
        <MeasurementToggleButtons />
      </div>
    </div>
    <Hr sx={{ mx: [16, 18], borderTopColor: 'veryLightGray' }} />
    <div
      sx={{
        mt: [16, 18],
        mx: [16, 18],
        mb: [26, 30],
      }}
    >
      <Ingredients isKorean={isKorean} ingredients={ingredients} />
    </div>
    {steps.map((step, index) => (
      <RecipeStep
        key={index}
        title={`${index + 1}. ${step.title}`}
        description={step.description}
        sx={{
          borderTopColor: 'lightGray',
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
        }}
      />
    ))}
    {tip && tip.title && tip.description && (
      <RecipeStep
        title={`* ${tip.title}`}
        description={tip.description}
        sx={{
          borderTopColor: 'lightGray',
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
        }}
      />
    )}
  </div>
);

const ListedPortableText = ({ blocks }) => (
  <PortableText
    blocks={blocks}
    sx={
      blocks.length > 1
        ? {
            'p:before': {
              content: '"—"',
              fontSize: 14,
              lineHeight: '2.42em',
              position: 'absolute',
              marginLeft: -30,
            },
          }
        : {}
    }
  />
);

const DesktopView = ({
  isKorean,
  _rawRecipe: { servings, ingredients, steps, tip },
}) => (
  <div
    sx={{
      display: 'flex',
      alignItems: 'stretch',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'lightGray',
    }}
  >
    <div
      sx={{
        width: desktopGrid(4, 3),
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: 'lightGray',
      }}
    >
      <Text variant="recipeTitle" sx={{ m: [22] }}>
        {isKorean ? '재료' : 'Ingredients'}
      </Text>
      <Hr />
      <div sx={{ m: 22 }}>
        <Text variant="recipeBody">{servings}</Text>
        <Hr sx={{ mt: 22, mb: 20, borderTopColor: 'veryLightGray' }} />
        <MeasurementToggleButtons />
        <Hr sx={{ mt: 20, mb: 22, borderTopColor: 'veryLightGray' }} />
        <Ingredients isKorean={isKorean} ingredients={ingredients} />
      </div>
    </div>
    <div sx={{ width: desktopGrid(8, 8) }}>
      <Text variant="recipeTitle" sx={{ m: [22] }}>
        {isKorean ? '만든 과정' : 'Recipes'}
      </Text>
      <Hr />
      <div
        sx={{
          mt: 14,
          mb: 34,
          ml: 22,
          mr: 34,
          display: 'grid',
          gridTemplateColumns: '1fr',
          rowGap: 34,
          gridRowGap: 34,
          fontFamily: 'recipeBody',
          fontSize: 18,
          lineHeight: '1.88em',
        }}
      >
        {steps.map((step, index) => (
          <div key={index} sx={{ display: 'flex' }}>
            <div
              sx={{
                width: 30,
                flexShrink: 0,
                flexGrow: 0,
                fontWeight: 'medium',
              }}
            >
              {index + 1}.
            </div>
            <div>
              <p sx={{ fontWeight: 'medium' }}>{step.title}</p>
              <ListedPortableText blocks={step.description} />
            </div>
          </div>
        ))}
        {tip && tip.title && tip.description && (
          <div sx={{ display: 'flex' }}>
            <div
              sx={{
                width: 30,
                flexShrink: 0,
                flexGrow: 0,
                fontWeight: 'medium',
              }}
            >
              *
            </div>
            <div>
              <p sx={{ fontWeight: 'medium' }}>{tip.title}</p>
              <ListedPortableText blocks={tip.description} />
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default ({ className, isKorean, _rawRecipe }) => {
  const { isDesktop } = useMedia();
  return (
    <div
      className={className}
      sx={{
        mx: [24, tabletGrid(2, 2), desktopContainerMarginX],
      }}
    >
      {isDesktop ? (
        <DesktopView isKorean={isKorean} _rawRecipe={_rawRecipe} />
      ) : (
        <SmallView isKorean={isKorean} _rawRecipe={_rawRecipe} />
      )}
    </div>
  );
};
