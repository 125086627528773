import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.24997 3.00019L14.25 9.00426L5.24997 15.0002L5.24997 13.8179L12.2075 9.15959L12.4648 9.00409L12.1969 8.84181L5.24997 4.19084L5.24997 3.00019Z"
      fill="#2B2C2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25205 2.99707L14.2446 8.99615L14.252 9.00738L5.25673 15.0002H5.24622L5.24622 13.8123L5.24788 13.8148L12.2054 9.15647L12.4576 9.00409L12.1949 8.84502L5.24323 4.19084H5.24622L5.24622 3.00581L5.25205 2.99707ZM5.25372 3.0072L5.25372 4.18884L12.1988 8.8386L12.4606 8.99717L12.4667 9.0073L12.2096 9.16271L5.25372 13.8199L5.25372 14.9932L14.2432 9.00426L5.25372 3.0072Z"
      fill="#2B2C2A"
    />
  </svg>
);
