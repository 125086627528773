/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { convertUnit } from '../lib/helpers';
import { useMeasurement } from '../hooks';

const measurementLabel = {
  kilogram: {
    ko: 'kg',
    en: 'kg',
  },
  gram: {
    ko: 'g',
    en: 'g',
  },
  liter: {
    ko: 'ℓ',
    en: 'ℓ',
  },
  milliliter: {
    ko: 'mℓ',
    en: 'mℓ',
  },
  teaspoon: {
    ko: '작은술',
    en: 'tsp.',
  },
  tablespoon: {
    ko: '큰술',
    en: 'Tbsp.',
  },
};

const fractionMap = {
  '0': 0,
  '½': 1 / 2,
  '⅓': 1 / 3,
  '⅔': 2 / 3,
  '¼': 1 / 4,
  '¾': 3 / 4,
};

function getNumber(amount, fraction) {
  return Number(amount || 0) + (fractionMap[fraction] || 0);
}

function Renderer({ name, amount, unit, customAmount, description, isKorean }) {
  if (isKorean) {
    if (customAmount) {
      return (
        <div>
          {name} {customAmount}
          {description ? ` (${description})` : ''}
        </div>
      );
    } else {
      return (
        <div>
          {name} {amount} {unit}
          {description ? ` (${description})` : ''}
        </div>
      );
    }
  }

  // English
  if (customAmount) {
    return (
      <div>
        {customAmount}
        {customAmount.trim() && <span>&nbsp;</span>} {name}
        {description ? ` (${description})` : ''}
      </div>
    );
  } else {
    return (
      <div>
        {amount} {unit}&nbsp; {name}
        {description ? ` (${description})` : ''}
      </div>
    );
  }
}

export default function MeasuredIngredient({
  unit,
  customAmount,
  amount,
  fraction,
  ingredient,
  isKorean,
  description,
}) {
  const { measurement } = useMeasurement();
  const { koreanName, englishName } = ingredient.ingredient;
  const lang = isKorean ? 'ko' : 'en';
  const name = isKorean ? koreanName : englishName;

  if (customAmount) {
    return (
      <Renderer
        name={name}
        customAmount={customAmount}
        description={description}
        isKorean={isKorean}
      />
    );
  }

  if (unit === 'teaspoon' || unit === 'tablespoon') {
    return (
      <Renderer
        name={name}
        amount={[amount, fraction]
          .filter(x => Boolean(x) && x !== '0')
          .join(' ')}
        unit={measurementLabel[unit][lang]}
        description={description}
        isKorean={isKorean}
      />
    );
  }

  const originalAmount = getNumber(amount, fraction);
  if (measurement === 'g') {
    return (
      <Renderer
        name={name}
        amount={[amount, fraction]
          .filter(x => Boolean(x) && x !== '0')
          .join(' ')}
        unit={measurementLabel[unit][lang]}
        description={description}
        isKorean={isKorean}
      />
    );
  }

  // now measurement === 'oz'
  const converted = convertUnit({
    value: originalAmount,
    unit,
    isMetric: false,
  });

  return (
    <Renderer
      name={name}
      amount={converted.value}
      unit={converted.unit}
      description={description}
      isKorean={isKorean}
    />
  );
}
