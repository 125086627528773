/** @jsx jsx */
import { jsx } from 'theme-ui';
import PortableText from './body-portable-text';
import Recipe from './recipe';

function canRenderRecipe({ hasRecipe, _rawRecipe }) {
  const { servings, ingredients, steps } = _rawRecipe || {};

  return (
    hasRecipe &&
    _rawRecipe &&
    servings &&
    ingredients &&
    ingredients.length > 0 &&
    steps &&
    steps.length > 0
  );
}

function BlogPost({ className, _rawBody, isKorean, hasRecipe, _rawRecipe }) {
  return (
    <article className={className}>
      {_rawBody && <PortableText blocks={_rawBody} />}
      {canRenderRecipe({ hasRecipe, _rawRecipe }) && (
        <Recipe
          _rawRecipe={_rawRecipe}
          isKorean={isKorean}
          sx={{
            mt: [64, 108, 120],
          }}
        />
      )}
    </article>
  );
}

export default BlogPost;
