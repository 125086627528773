/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { useState, useCallback } from 'react';
import { graphql, Link } from 'gatsby';
import GraphQLErrorList from '../components/graphql-error-list';
import BlogPost from '../components/blog-post';
import SEO from '../components/seo';
import Layout from '../components/layout';
import NavBar from '../components/nav-bar';
import Footer from '../components/footer';
import Titles from '../components/titles';
import ResponsiveMainImage from '../components/responsive-main-image';
import BreadcrumbTrigger from '../components/breadcrumb-trigger';
import { toPlainText, getBlogUrl } from '../lib/helpers';
import { navBarHeightsInArray } from '../components/const';
import SubscribeAndMadeBy from '../components/subscribe-and-made-by';
import Recommendation from '../components/recommendation';
import { useMedia } from '../hooks';

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      mainImage2 {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      title
      slug {
        current
      }
      isKorean
      hasRecipe
      _rawBody(resolveReferences: { maxDepth: 5 })
      _rawRecipe(resolveReferences: { maxDepth: 10 })
      anotherVersion {
        post {
          slug {
            current
          }
          title
          isKorean
        }
      }
      category {
        _key
        _type
        category {
          name
          slug {
            current
          }
        }
      }
      recommendedPosts {
        post {
          id
          slug {
            _key
            _type
            current
          }
          isKorean
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
        }
      }
      author {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          name
        }
      }
    }
    allSanityPost {
      edges {
        node {
          id
          isKorean
          slug {
            _key
            _type
            current
          }
          mainImage {
            _key
            _type
            alt
            asset {
              _id
            }
          }
          hasRecipe
        }
      }
    }
  }
`;

function getMainImageAspectRatio(isMobile, isTablet) {
  if (isMobile) {
    return 3 / 2;
  }
  if (isTablet) {
    return 1;
  }
  return 2 / 3;
}

const Breadcrumb = ({ hasRecipe, title, lang }) => (
  <p
    sx={{
      '& span': {
        fontSize: [14, 16],
        lineHeight: '1em',
      },
    }}
  >
    {hasRecipe && (
      <>
        <Link to={`/recipes/${lang}`}>
          <span sx={{ fontFamily: 'breadcrumb1', color: 'lightGray' }}>
            Recipes
          </span>
        </Link>
        <span sx={{ fontFamily: 'breadcrumb1', mx: [6, 8] }}>/</span>
      </>
    )}
    <span sx={{ fontFamily: 'breadcrumb2' }}>{title}</span>
  </p>
);

const getRecommendedPostNodes = ({ data, post }) => {
  return [
    ...(post.recommendedPosts
      ? post.recommendedPosts.map(obj => obj.post)
      : []),
    ...data.allSanityPost.edges
      .map(edge => edge.node)
      .filter(node => node.id !== post.id)
      .filter(node => node.isKorean === post.isKorean),
  ];
};

const BlogPostTemplate = props => {
  const { isMobile, isTablet } = useMedia();
  const aspectRatio = getMainImageAspectRatio(isMobile, isTablet);

  const { data, path, errors } = props;
  const post = data && data.post;
  const lang = post.isKorean ? 'ko' : 'en';
  const recommendedPostNodes = getRecommendedPostNodes({ data, post });
  const imageTopMargins = [42, 58, 60];
  const imageBottomMargins = [32, 36, 80];
  return (
    <Layout lang={lang}>
      {errors && <SEO title="GraphQL Error" path={path} />}
      {post && (
        <SEO
          title={post.title}
          description={toPlainText(post._rawBody)}
          lang={lang}
          image={post.mainImage2 || post.mainImage}
          path={path}
        />
      )}
      {errors && <GraphQLErrorList errors={errors} />}
      <NavBar
        lang={lang}
        showLangToggleButton={false}
        renderBreadcrumb={() => (
          <Breadcrumb
            hasRecipe={post.hasRecipe}
            title={post.title}
            lang={lang}
          />
        )}
      />
      <div sx={{ pt: [96, 136, 160] }}></div>
      <Titles
        sx={{
          mt: navBarHeightsInArray,
        }}
        title={post.title}
        subtitle={post.anotherVersion && post.anotherVersion.post.title}
        subtitleUrl={
          post.anotherVersion &&
          getBlogUrl(
            post.anotherVersion.post.isKorean,
            post.anotherVersion.post.slug
          )
        }
      />
      <BreadcrumbTrigger level="lvl1" sx={{ mt: imageTopMargins }} />
      <ResponsiveMainImage
        node={post.mainImage2 || post.mainImage}
        aspectRatio={aspectRatio}
        sx={{ mt: imageTopMargins, mb: imageBottomMargins }}
      />
      <BreadcrumbTrigger
        level="lvl2"
        sx={{ mt: imageBottomMargins.map(m => m * -1) }}
      />
      {post && <BlogPost {...post} />}
      <Recommendation
        nodes={recommendedPostNodes}
        sx={{ mt: [96, 162, 160] }}
      />
      <SubscribeAndMadeBy lang={lang} sx={{ mt: [94, 160, 180] }} />
      <Footer showLangToggleButton={false} sx={{ mt: 66 }} />
    </Layout>
  );
};

export default BlogPostTemplate;
