import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 6.00004L8.99597 13.5L3.00004 6.00004L4.18231 6.00004L8.84064 11.798L8.99614 12.0124L9.15842 11.7891L13.8094 6.00004L15 6.00004Z"
      fill="#2B2C2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0469 5.97754L8.99596 13.5361L2.95325 5.97754L4.19309 5.97754L8.85888 11.7848L8.99616 11.9741L9.14086 11.775L13.7986 5.97754L15.0469 5.97754ZM14.9532 6.02254L13.8202 6.02254L9.1763 11.8028L8.99612 12.0507L8.82275 11.8117L4.17152 6.02254L3.04684 6.02254L8.99598 13.464L14.9532 6.02254Z"
      fill="#2B2C2A"
    />
  </svg>
);
