/** @jsx jsx */
import React from 'react';
import { jsx, Text } from 'theme-ui';
import { useMeasurement } from '../hooks';

const list = [
  { text: 'g', value: 'g' },
  { text: 'oz', value: 'oz' },
];

export default function MeasurementToggleButtons({ className }) {
  const { measurement, setMeasurement } = useMeasurement();

  return (
    <div className={className} sx={{ display: 'flex', alignItems: 'center' }}>
      {list.map(({ text, value }, index) => (
        <React.Fragment key={text}>
          {measurement === value ? (
            <Text
              variant="measurementLabel"
              key={text}
              sx={{
                color: 'black',
              }}
            >
              {text}
            </Text>
          ) : (
            <button
              type="button"
              sx={{
                background: 'transparent',
                padding: 0,
                margin: 0,
                border: 0,
                cursor: 'pointer',
              }}
              onClick={() => setMeasurement(value)}
            >
              <Text variant="measurementLabel">{text}</Text>
            </button>
          )}
          {index < list.length - 1 && (
            <Text variant="measurementLabel" sx={{ mx: [8, 10, 10] }}>
              /
            </Text>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
