/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { containerMarginX } from './const';
import Figure from './Figure';
import { useMedia } from '../hooks';
import { getBlogUrl } from '../lib/helpers';

const getNumToRecommend = ({ isMobile, isTablet }) => {
  if (isMobile) {
    return 2;
  } else if (isTablet) {
    return 3;
  } else {
    return 4;
  }
};

export default ({ className, nodes }) => {
  const { isMobile, isTablet } = useMedia();
  const numToRecommend = getNumToRecommend({ isMobile, isTablet });

  return (
    <div
      className={className}
      sx={{
        mx: containerMarginX,
      }}
    >
      <p
        sx={{
          fontFamily: 'recommendation',
          fontSize: [16, 18, 18],
          lineHeight: '1em',
          color: 'black',
        }}
      >
        Recommended
      </p>
      <div
        sx={{
          borderTopColor: 'lightGray',
          borderTopStyle: 'solid',
          borderTopWidth: '1px',
          mx: [-4, -6, -14],
          mt: [16, 16, 16],
          mb: [16, 16, 32],
        }}
      ></div>
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr 1fr'],
          columnGap: [6, 8, 10],
          gridColumnGap: [6, 8, 10],
        }}
      >
        {nodes.slice(0, numToRecommend).map(node => (
          <Link to={getBlogUrl(node.isKorean, node.slug)} key={node.id}>
            <Figure aspectRatio={1} node={node.mainImage} />
          </Link>
        ))}
      </div>
    </div>
  );
};
